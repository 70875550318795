import classNames from 'classnames';

interface FilterHeaderProps {
  title: string;
  clearFiltersText?: string;
  closeFilter?: () => void;
  displayClearFiltersAsLink?: boolean;
  isSmallTitle?: boolean;
  resetFilters?: () => void;
  showBackButton?: boolean;
  showClearButton?: boolean;
  subtext?: string;
  titleSubtext?: string;
}

const FilterHeader = ({
  clearFiltersText = 'Clear filters',
  closeFilter,
  displayClearFiltersAsLink,
  isSmallTitle,
  resetFilters,
  showBackButton,
  showClearButton,
  subtext,
  title,
  titleSubtext,
}: FilterHeaderProps) => {
  return (
    <div className="ch-modal__header filter__header">
      {showBackButton && closeFilter && (
        <button
          className="ch-btn filter__header-back"
          onClick={closeFilter}
          type="button"
        >
          Back
        </button>
      )}
      <div className="ch-pa--2 ch-display--flex ch-flex-flow--row-wrap ch-justify-content--between ch-align-items--center ch-width--12">
        <h3
          className={classNames(`ch-mb--0 ch-fs--${isSmallTitle ? 3 : 4}`, {
            'ch-width--6': showClearButton,
            'ch-width--7': showClearButton && isSmallTitle,
          })}
        >
          {title}
          {titleSubtext && <span className="ch-fw--400"> {titleSubtext}</span>}
        </h3>
        <div>
          {subtext && (
            <p className="ch-mt--2 ch-mb--0 ch-fs--3 ch-color--grey-6">
              {subtext}
            </p>
          )}
        </div>
        {showClearButton && resetFilters && (
          <button
            className={classNames(
              'ch-btn ch-btn--sm ch-color--ac-red ch-flex--none',
              displayClearFiltersAsLink &&
                'ch-btn--link ch-fs--3 ch-fw--500 ch-ph--0'
            )}
            onClick={resetFilters}
            type="button"
          >
            {clearFiltersText}
          </button>
        )}
      </div>
    </div>
  );
};

export default FilterHeader;
