import React from 'react';
import PropTypes from 'prop-types';

const ProgressDisplay = ({
  currentStep,
  exit,
  stepName,
  stepped,
  totalSteps,
}) => {
  const progress =
    currentStep > totalSteps
      ? 100
      : Math.round((100 / totalSteps) * currentStep);

  const complete = currentStep > totalSteps;

  return (
    <div
      className={`ch-stepper ${
        complete && 'ch-stepper--complete'
      } ch-pt--1 ch-ph--2`}
    >
      <header aria-live="assertive" className="ch-stepper__header ch-mb--1">
        <a className="ac-progress" href={exit} />
        <h1 className="ch-mb--0 ch-fs--3">{stepName}</h1>
        <span className="ch-stepper__percentage">
          <span className="ch-fw--500">{progress}%</span> complete
        </span>
      </header>
      <div
        className={`ch-progress ch-mh--n2 sm:ch-mh--0 ${
          stepped && `ch-progress--${totalSteps}-step`
        }`}
      >
        <div
          className="ch-progress__bar"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressDisplay;

ProgressDisplay.propTypes = {
  currentStep: PropTypes.number.isRequired,
  exit: PropTypes.string,
  stepName: PropTypes.string,
  stepped: PropTypes.bool,
  totalSteps: PropTypes.number.isRequired,
};
