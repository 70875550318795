const FiltersNoResults = () => (
  <section
    aria-labelledby="no-results-heading"
    className="filter__no-results-content ch-display--flex ch-flex-direction--column ch-align-items--center ch-width--12 filter__button"
  >
    <header>
      <h2 className="ch-mv--1 sm:ch-mt--0 ch-fs--4" id="no-results-heading">
        No results found
      </h2>
    </header>
    <p className="ch-fs--3 ch-mb--0">Try removing some filters</p>
    <div className="ch-text-divider ch-width--12 ch-mv--2 sm:ch-mv--3">
      <p className="ch-fs--3 ch-fw--400 ch-mb--0 ch-color--grey-6">Or</p>
    </div>
  </section>
);

export default FiltersNoResults;
