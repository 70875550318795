import { Endpoint } from '@javascript/types/Requests';
import classNames from 'classnames';

const StockAlertLauncher = ({
  additionalStyling,
  analyticsLocation,
  stockAlertUrl = '/stock-alerts/new',
}: {
  additionalStyling?: string;
  analyticsLocation?: string;
  stockAlertUrl?: Endpoint;
}) => (
  <a
    className={classNames('ch-btn js-off--hide', additionalStyling)}
    data-gtm-track="interaction"
    data-gtm-track-action={`Opened stock alerts wizard${
      analyticsLocation ? ` - ${analyticsLocation}` : ''
    }`}
    href={stockAlertUrl}
  >
    Create a stock alert
  </a>
);

export default StockAlertLauncher;
