//TODO: this should probably be moved to offers, where it's being used
import React from 'react';
import PropTypes from 'prop-types';

const MakeAndModelSelector = ({
  handleSelect,
  makeModel,
  makesDisplay,
  selectedMake,
  selectedModel,
  modelText,
}) => {
  return (
    <>
      <div className="ch-form__group xs:ch-col--12 sm:ch-col--3 ac-col--compact js-off--hide">
        <label className="ch-reader" htmlFor="make">
          Make
        </label>
        <select
          className="ch-form__control"
          id="make"
          onBlur={(evt) => handleSelect(evt)}
          onChange={(evt) => handleSelect(evt)}
          value={selectedMake}
        >
          <option key="All makes" value="">
            All makes
          </option>
          {Object.keys(makeModel).map((make) => (
            <option key={make} value={make}>
              {makesDisplay[make]}
            </option>
          ))}
        </select>
      </div>

      <div className="ch-form__group xs:ch-col--12 sm:ch-col--3 ac-col--compact js-off--hide">
        <label className="ch-reader" htmlFor="model">
          Model
        </label>
        <select
          className="ch-form__control"
          id="model"
          onBlur={(evt) => handleSelect(evt)}
          onChange={(evt) => handleSelect(evt)}
          value={selectedModel}
        >
          <option key="All models" value="_all">
            {modelText}
          </option>
          {selectedMake &&
            makeModel[selectedMake].map(({ key, display }) => (
              <option key={key} value={key}>
                {display}
              </option>
            ))}
        </select>
      </div>
      {!!selectedMake && (
        <input
          name={`make_model[${selectedMake}][]`}
          type="hidden"
          value={selectedModel || '_all'}
        />
      )}
    </>
  );
};

export default MakeAndModelSelector;

MakeAndModelSelector.propTypes = {
  handleSelect: PropTypes.func,
  makeModel: PropTypes.object,
  makesDisplay: PropTypes.object,
  modelText: PropTypes.string,
  selectedMake: PropTypes.string,
  selectedModel: PropTypes.string,
};

MakeAndModelSelector.defaultProps = {
  selectedMake: '',
  selectedModel: '',
  modelText: 'All models',
};
