type GoogleAddressComponentType =
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'administrative_area_level_6'
  | 'administrative_area_level_7'
  | 'archipelago'
  | 'colloquial_area'
  | 'continent'
  | 'country'
  | 'establishment'
  | 'finance'
  | 'floor'
  | 'food'
  | 'general_contractor'
  | 'geocode'
  | 'health'
  | 'intersection'
  | 'landmark'
  | 'locality'
  | 'natural_feature'
  | 'neighborhood'
  | 'place_of_worship'
  | 'plus_code'
  | 'point_of_interest'
  | 'political'
  | 'post_box'
  | 'postal_code'
  | 'postal_code_prefix'
  | 'postal_code_suffix'
  | 'postal_town'
  | 'premise'
  | 'room'
  | 'route'
  | 'street_address'
  | 'street_number'
  | 'sublocality'
  | 'sublocality_level_1'
  | 'sublocality_level_2'
  | 'sublocality_level_3'
  | 'sublocality_level_4'
  | 'sublocality_level_5'
  | 'subpremise'
  | 'town_square';

type DuplicateKey = `${GoogleAddressComponentType}_duplicates`;

export interface GoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: GoogleAddressComponentType[];
}

type MappedAddressComponents = {
  [key in GoogleAddressComponentType]: string;
} & {
  [key in DuplicateKey]: string[];
};

export function mapAddressComponents(
  addressComponents: GoogleAddressComponent[]
): MappedAddressComponents {
  return addressComponents.reduce((mappedComponents, { long_name, types }) => {
    types.forEach((t) => {
      if (!mappedComponents[t]) {
        // If the key does not exist, set it
        mappedComponents[t] = long_name;
      } else {
        // If the key exists, add the new value under `${t}_duplicates to avoid overwriting any original value
        // (sometimes google has multiple values for the same type)
        const duplicateKey: DuplicateKey = `${t}_duplicates`;
        if (!mappedComponents[duplicateKey]) {
          mappedComponents[duplicateKey] = [];
        }
        // Add the new duplicate value to the array
        mappedComponents[duplicateKey].push(long_name);
      }
    });

    return mappedComponents;
  }, {} as MappedAddressComponents);
}

export function locationFormatter(addressComponents: GoogleAddressComponent[]) {
  const address = mapAddressComponents(addressComponents);

  const {
    floor,
    postal_code,
    postal_town,
    room,
    route,
    street_address,
    street_number,
    sublocality,
    sublocality_level_1,
    sublocality_level_2,
  } = address;

  return {
    address1: [room, floor, street_number, street_address, route]
      .filter(Boolean)
      .join(' '),
    address2: sublocality || sublocality_level_1 || sublocality_level_2,
    city: postal_town,
    postcode: postal_code,
  };
}
