//TODO: move this into the hooks folder

import { useState, useEffect, useRef, useCallback } from 'react';

// Helper hook to identify previous state
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const useWindowEventListener = (eventName, handler) => {
  useEffect(() => {
    window.addEventListener(eventName, handler);
    return () => window.removeEventListener(eventName, handler);
  }, [eventName, handler]);
};

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, toggle];
};

export { usePrevious, useToggle, useWindowEventListener };
