import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ValidPostcode,
  ValidPhoneNumber,
  ValidEmailAddress,
  ValidName,
} from '@lib/regularExpressions';
import localStorageAvailable from '@helpers/localStorage';

export default class TextInput extends Component {
  static propTypes = {
    additionalErrorMessageClasses: PropTypes.string,
    additionalInputClasses: PropTypes.string,
    additionalLabelClasses: PropTypes.string,
    autocomplete: PropTypes.string,
    description: PropTypes.string,
    errorMessage: PropTypes.string,
    inputId: PropTypes.string,
    inputName: PropTypes.string,
    inputType: PropTypes.string,
    labelName: PropTypes.string,
    min: PropTypes.number,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    requiresEmailValidation: PropTypes.bool,
    requiresNameValidation: PropTypes.bool,
    requiresPhoneNumberValidation: PropTypes.bool,
    requiresPostcodeValidation: PropTypes.bool,
    requiresValidation: PropTypes.bool,
    storeKey: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    inputType: 'text',
    errorMessage: 'This field is required.',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || this.getValue(),
      isValid: true,
      hasBeenValidated: false,
    };
  }

  getValue = () => {
    if (!localStorageAvailable()) return '';
    if (this.props.storeKey) {
      const storedValue = localStorage.getItem(this.props.storeKey);
      if (storedValue !== null) {
        if (this.props.inputType === 'number') return Number(storedValue);
        return storedValue;
      }
    }
    return '';
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
    if (this.props.onChange) {
      return this.props.onChange(event);
    }
  };

  checkValidationRequired = () => {
    if (
      this.props.requiresValidation ||
      this.props.requiresEmailValidation ||
      this.props.requiresPhoneNumberValidation ||
      this.props.requiresPostcodeValidation ||
      this.props.requiresNameValidation
    ) {
      this.handleValidation();
    }
  };

  handleValidation = () => {
    this.setState({ hasBeenValidated: true });

    if (this.props.requiresValidation) {
      return this.validateNotEmpty();
    }
    if (this.props.requiresEmailValidation) {
      return this.validateEmail();
    }
    if (this.props.requiresPhoneNumberValidation) {
      return this.validatePhoneNumber();
    }
    if (this.props.requiresPostcodeValidation) {
      return this.validatePostcode();
    }
    if (this.props.requiresNameValidation) {
      return this.validateName();
    }

    return false;
  };

  validateNotEmpty = () => {
    if (this.state.value) {
      this.setState({ isValid: true });
      return true;
    }

    this.setState({ isValid: false });
  };

  validatePhoneNumber = () => {
    if (ValidPhoneNumber.test(this.state.value)) {
      this.setState({ isValid: true });
      return true;
    }

    this.setState({ isValid: false });
  };

  validatePostcode = () => {
    if (ValidPostcode.test(this.state.value)) {
      this.setState({ isValid: true });
      return true;
    }

    this.setState({ isValid: false });
  };

  validateEmail = () => {
    if (ValidEmailAddress.test(this.state.value)) {
      this.setState({ isValid: true });
      return true;
    }

    this.setState({ isValid: false });
  };

  validateName = () => {
    if (ValidName.test(this.state.value)) {
      this.setState({ isValid: true });
      return true;
    }

    this.setState({ isValid: false });
  };

  renderValidationError = () => {
    if (!this.state.isValid) {
      return (
        <p
          className={classNames(
            'ch-form__control-validation ac-animation--fade-in ',
            this.props.additionalErrorMessageClasses
          )}
          ref="errorMessage"
        >
          {this.props.errorMessage}
        </p>
      );
    }
  };

  renderDescription = () => {
    if (this.props.description) {
      return (
        <span className="ac-input__description" ref="description">
          {this.props.description}
        </span>
      );
    }
  };

  render() {
    return (
      <div
        className={classNames('ch-form__group', {
          'ch-form__group--error': !this.state.isValid,
        })}
      >
        <label
          className={classNames('ch-label', this.props.additionalLabelClasses)}
          htmlFor={this.props.inputId}
          ref="label"
        >
          {this.props.labelName}
        </label>
        {this.renderDescription()}
        {this.renderValidationError()}
        <input
          autoComplete={this.props.autocomplete}
          className={classNames(
            'ch-form__control',
            this.props.additionalInputClasses,
            {
              'ac-validation--invalid':
                !this.state.isValid && this.state.hasBeenValidated,
            },
            {
              'ac-validation--valid':
                this.state.isValid && this.state.hasBeenValidated,
            }
          )}
          id={this.props.inputId}
          min={this.props.min}
          name={this.props.inputName}
          onBlur={this.checkValidationRequired}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          ref="input"
          type={this.props.inputType}
          value={this.state.value}
        />
      </div>
    );
  }
}
