import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from '@components/application/modal';
import VehicleSearchHeader from './vehicleSearchHeader';
import FallbackFilters from './filters/fallbackFilters';
import LoadingSpinner from './loadingSpinner';
import VehicleSearchFiltersContainer from './vehicleSearchFiltersContainer';
import withSearchTags from './withSearchTags';
import { buildFiltersQueryString } from '@lib/requests/vehicleSearch';
import ShowMore from './showMore';
import BestDealGuaranteeBanner from '@components/application/banners/bestDealGuaranteeBanner';
import RealSaleBanner from '@components/application/banners/realSaleBanner';
import FilterFooter from '../search_filters/filterFooter';
import FilterHeader from '../search_filters/filterHeader';
import EventEmitter, { EVENT_TYPES } from '@javascript/lib/eventEmitter';
import { ButtonType } from '@javascript/types/ButtonType';
import FiltersNoResults from '../search_filters/units/filtersNoResults';
import StockAlertLauncher from '../stock_alerts/stockAlertLauncher';
import { createStockAlertUrl } from '@javascript/stock_alerts/helpers';
import { pluralise } from '@javascript/lib/utils';
import Analytics from '@javascript/lib/analytics';

const getPaginationParameters = (appliedFilters) =>
  buildFiltersQueryString(appliedFilters).replace(/&page=[1-9]/g, '');

const NoResultsFooter = ({ appliedFilters, showAllCars }) => (
  <div className="filter__no-results ch-display--flex ch-flex-direction--column ch-align-items--center ch-pa--3 sm:ch-pv--4 ch-bg--white ">
    <FiltersNoResults />
    <button
      className="ch-btn ch-btn--secondary ch-width--12 ch-mb--2"
      onClick={showAllCars}
      type="button"
    >
      Let me see all cars
    </button>
    <StockAlertLauncher
      additionalStyling="ch-width--12 ch-bc--grey-6"
      analyticsLocation="SERPs 0 results"
      stockAlertUrl={createStockAlertUrl(appliedFilters)}
    />
  </div>
);

const VehicleSearchPage = ({
  appliedFilters,
  availableOptions,
  experiments,
  isRealSale,
  onFilterCriteriaUpdate,
  loading,
  resetFilters,
  result,
  shouldResetScroll,
  authenticityToken,
}) => {
  const SearchHeaderWithTags = withSearchTags(VehicleSearchHeader);

  const {
    count,
    pagination: { currentPage },
  } = result;

  const buttonText = `Show ${count > 1 ? `all ${count}` : count} ${pluralise(
    count,
    'car'
  )}`;

  const closeModal = () => {
    EventEmitter.emit(EVENT_TYPES.closeModal, 'filters');
  };

  const showAllCars = () => {
    Analytics.trackEvent('interaction', {
      action: 'Filters search',
      label: 'Let me see all cars - 0 results',
    });
    closeModal();
    resetFilters();
  };

  return (
    <div
      className={classNames('ac-search', {
        'ac-search--loading': loading,
      })}
    >
      {result.banner && (
        <div className="ac-search__banner js-off--hide">
          <div className="ch-container">
            <div className="ac-promo__wrapper">
              {isRealSale ? (
                <RealSaleBanner
                  isSERPS
                  isSale={result.banner.isSale}
                  text={result.banner.text}
                />
              ) : (
                <BestDealGuaranteeBanner />
              )}
            </div>
          </div>
        </div>
      )}
      <SearchHeaderWithTags
        appliedFilters={appliedFilters}
        count={count}
        experiments={experiments}
        filters={{
          ...appliedFilters,
        }}
        onFilterCriteriaUpdate={onFilterCriteriaUpdate}
        onRemoveAllTags={resetFilters}
        paymentType={appliedFilters.paymentType}
        searchTitle={result.searchTitle}
        sortOrder={appliedFilters.sortOrder}
      />
      <div className="ch-container">
        <FallbackFilters
          branchId={appliedFilters.branchId}
          branchName={appliedFilters.branchName}
          makeModel={appliedFilters.makeModel}
        />
        <LoadingSpinner isLoading={loading} />

        <ShowMore
          appliedFilters={appliedFilters}
          authenticityToken={authenticityToken}
          baseUrl="/vehicles?"
          count={count}
          currentPage={currentPage}
          experiments={experiments}
          isLoading={loading}
          onFilterCriteriaUpdate={onFilterCriteriaUpdate}
          parameters={getPaginationParameters(appliedFilters)}
          result={result}
        />

        <Modal id="filters" shouldResetScroll={shouldResetScroll}>
          <VehicleSearchFiltersContainer
            appliedFilters={appliedFilters}
            availableOptions={availableOptions}
            count={count}
            experiments={experiments}
            filterFooter={
              count === 0 && !loading ? (
                <NoResultsFooter
                  appliedFilters={appliedFilters}
                  showAllCars={showAllCars}
                />
              ) : (
                <FilterFooter
                  buttonText={buttonText}
                  buttonType={ButtonType.primary}
                  isLoading={loading}
                  onClick={closeModal}
                />
              )
            }
            filterHeader={
              <FilterHeader
                closeFilter={closeModal}
                resetFilters={resetFilters}
                showBackButton
                showClearButton
                title={'Filter results'}
              />
            }
            isRealSale={isRealSale}
            loading={loading}
            onFilterCriteriaUpdate={onFilterCriteriaUpdate}
            resetFilters={resetFilters}
          />
        </Modal>
      </div>
    </div>
  );
};

export default VehicleSearchPage;

NoResultsFooter.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  showAllCars: PropTypes.func.isRequired,
};

VehicleSearchPage.propTypes = {
  appliedFilters: PropTypes.object,
  authenticityToken: PropTypes.string,
  availableOptions: PropTypes.object,
  experiments: PropTypes.object,
  isRealSale: PropTypes.bool,
  loading: PropTypes.bool,
  onFilterCriteriaUpdate: PropTypes.func.isRequired,
  resetFilters: PropTypes.func,
  result: PropTypes.shape({
    banner: PropTypes.bool,
    branch: PropTypes.string,
    clickAndCollectDetails: PropTypes.object,
    count: PropTypes.number,
    pagination: PropTypes.object,
    searchResults: PropTypes.array,
    searchTitle: PropTypes.string,
    stockChipData: PropTypes.array,
  }),
  shouldResetScroll: PropTypes.bool,
};
